body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* remove the padding-left on all uls */
ul {
  padding-left: 0 !important;
}

.btn:focus {
  box-shadow: none;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus {
  background-color: #2484c6;
  border-color: #2484c6;
}

.btn-outline-primary {
  border-color: #2484c6;
  color: #2484c6 !important;
}

.btn-outline-primary:hover,
.btn-outline-primary:active {
  background-color: #2484c6 !important;
  color: white !important;
}

.border-primary {
  border-color: #2484c6 !important;
}

.text-primary {
  color: #2484c6 !important;
}

.bg-primary {
  background-color: #2484c6 !important;
}

.btn-sm {
  border-radius: 5px;
}

/* STYLING FOR THE FORMS */
.form-floating > .form-control,
.form-floating > .form-select {
  height: 3rem;
  padding: 0 0.75rem;
  font-size: 14px;
}
.form-floating > label {
  padding: 0.3rem 0.5rem;
}

.brands-stores {
  background-color: rgba(32, 132, 198, 0.15);
}

.footer-inputs {
  background-color: rgba(32, 132, 198, 0.15);
}
/* ---------------------------------------------------------------------------------- */

/* STYLING FOR THE NAVIGATION BAR (MainNavbar) */
.top-navbar {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-top: 0.2rem;
}
.nav-link {
  padding: 0;
}
#sell-button {
  padding-left: 1rem;
  padding-right: 1rem;
}
.cart-badge {
  top: 70%;
  left: 90%;
  font-size: 1em;
  transform: translate(-50%, -50%);
}

/* ---------------------------------------------------------------------------------- */

/* STYLING FOR THE HOME PAGE */
#discover-kicstoms {
  padding-top: 2rem;
  padding-bottom: 4rem;
}

#get-your-sneakers {
  height: 600px;
  background-position: center;
  background-size: cover;
  padding: 0px;
  width: 100%;
}

#get-your-sneakers-content {
  padding-top: 10rem;
}

/* searchbox */

.MuiToolbar-root .ais-SearchBox {
  height: 60px;
  flex: 2 2 auto;
  margin: 0 1rem;
}

.MuiToolbar-root .ais-SearchBox .ais-SearchBox-input {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #dee2e6;
  height: 30px;
}

.MuiToolbar-root .ais-SearchBox .ais-SearchBox-input:focus::after {
  appearance: non ;
}

.MuiToolbar-root .ais-SearchBox-submit {
  padding: 0 1rem 0 2rem;
  width: 64px;
  color: #2484c6;
}

.MuiToolbar-root .ais-SearchBox .ais-SearchBox-input::placeholder {
  color: rgba(33, 36, 61, 0.24);
  opacity: 1;
}

/* ---------------------------------------------------------------------------------- */

/* STYLING FOR THE SHOPPING APP */

/* the shipping address selection styles */
.shipping-address-input {
  transform: translate(0, -10px);
}

/* the delivery option input styling */
.delivery-option-input {
  transform: translate(0, -10px);
}
/* --------------------------------------------------------------------------- */

/* STYLING FOR ALL MODALS */
.ks-modal {
  background: rgba(0, 0, 0, 0.6);
}

/* STYLING FOR THE FOOTER */
.footer-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footer-link {
  text-decoration: none;
  color: #2484c6;
}
.footer-link:hover {
  color: #2484c6;
}

.bg-warning {
  background-color: #faca2b !important;
}

.card-header {
  background-color: rgba(36, 132, 198, 0.03) !important;
}

.text-primary {
  color: #2484c6 !important;
}
